import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { Ancestry } from '../../core/api/portal.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.ancestry.errors && state.ancestry.errors ? true : false;

export const isBusySelector = (state: RootState): boolean => state.ancestry.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): Ancestry | null => state.ancestry.response || null;

export const hasSuccessSelector = (state: RootState): boolean => (state.ancestry.response ? true : false);
