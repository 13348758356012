import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { ConditionsState, ImmunisationsState, MedicationsState, AllergiesState } from './types';

export const hasErrorSelector = (state: RootState): boolean => (state.ehr.errors && state.ehr.errors ? true : false);

export const isBusySelector = (state: RootState): boolean => state.ehr.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): any => state.ehr.response || null;

export const hasSuccessSelector = (state: RootState): any | null => (state.ehr.response ? true : false);

export const ehrStatusSelector = (state: RootState): string =>
  state.ehr.response && state.ehr.response.ehrStatus ? state.ehr.response.ehrStatus.status : null;

export const immunisationsSelector = (state: RootState): ImmunisationsState => state.ehr.immunisations;

export const conditionsSelector = (state: RootState): ConditionsState => state.ehr.conditions;

export const medicationsSelector = (state: RootState): MedicationsState => state.ehr.medications;

export const allergiesSelector = (state: RootState): AllergiesState => state.ehr.allergies;
