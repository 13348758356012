import Typography from '@mui/material/Typography';
import { SectionBox, Formatter, theme } from '@omnigenbiodata/ui';
import { MedicationItem } from '../../../../../../core/api/portal.types';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { FaPills } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

interface MedicationsListProps {
  data: MedicationItem[];
}

const MedicationsList = ({ data }: MedicationsListProps) => {
  const { t } = useTranslation('portal');
  const acute = data.filter(({ frequency }) => frequency === 'ACUTE');
  const repeat = data.filter(({ frequency }) => frequency === 'REPEAT');
  return (
    <div>
      <SectionBox color="success">
        <Typography variant="h6" fontWeight="bold" color={theme.palette.primary.main}>
          {t('medications.acute')}
        </Typography>
        {acute.map((item: any, immIndex: number) => (
          <ListItem key={`acute-medications-${immIndex}`} sx={{ px: 0 }}>
            <ListItemAvatar>
              <Avatar
                variant="square"
                sx={{ bgcolor: theme.palette.secondary.main, height: '60px', width: '60px', marginRight: 2 }}
              >
                <FaPills />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="body1" component="p" fontWeight="bold" color={theme.palette.primary.main}>
                {item.medication}
              </Typography>
              <Typography variant="body2" component="p">
                {item.dosage}
              </Typography>
              <Typography variant="body2" component="p">
                <Formatter input={item.effectiveDate} format="friendlyDate" />
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </SectionBox>
      <SectionBox color="success">
        <Typography variant="h6" fontWeight="bold" color={theme.palette.primary.main}>
          {t('medications.repeat')}
        </Typography>
        {repeat.map((item: any, immIndex: number) => (
          <ListItem key={`repeat-medications-${immIndex}`} sx={{ px: 0 }}>
            <ListItemAvatar>
              <Avatar
                variant="square"
                sx={{ bgcolor: theme.palette.secondary.main, height: '60px', width: '60px', marginRight: 2 }}
              >
                <FaPills />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="body1" component="p" fontWeight="bold" color={theme.palette.primary.main}>
                {item.medication}
              </Typography>
              <Typography variant="body2" component="p">
                {item.dosage}
              </Typography>
              <Typography variant="body2" component="p">
                <Formatter input={item.effectiveDate} format="friendlyDate" />
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </SectionBox>
    </div>
  );
};

export default MedicationsList;
