/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Message, SectionBox, Tabs, Loader, Pulse } from '@omnigenbiodata/ui';
import { BiInjection, BiLeaf } from 'react-icons/bi';
import { BsJournalMedical } from 'react-icons/bs';
import { MdOutlineMedication } from 'react-icons/md';
import PortalLayout from '../../../../layouts/PortalLayout';
import { EhrStatus } from '../../../../core/types/common.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  ehrStatusSelector,
  immunisationsSelector,
  conditionsSelector,
  medicationsSelector,
  isBusySelector,
  getEhrStatusThunk,
  getImmunisationsThunk,
  getConditionsThunk,
  getMedicationsThunk,
  getAllergiesThunk,
  allergiesSelector,
} from '../../../../store/ehr';
import { FEATURES, flagActive } from '../../../../core/constants/features.constants';
import EhrTab from './components/EhrTab';
import SimpleList from './components/SimpleList';
import MedicationsList from './components/MedicationsList';

function EhrScene() {
  const { t } = useTranslation('portal');

  const dispatch = useAppDispatch();
  const isEhrBusy = useAppSelector(isBusySelector);
  const ehrStatus = useAppSelector(ehrStatusSelector);
  const immunisations = useAppSelector(immunisationsSelector);
  const conditions = useAppSelector(conditionsSelector);
  const medications = useAppSelector(medicationsSelector);
  const allergies = useAppSelector(allergiesSelector);

  useEffect(() => {
    dispatch(getEhrStatusThunk());
  }, [dispatch]);

  useEffect(() => {
    if (ehrStatus === EhrStatus.SUCCESS) {
      if (flagActive(FEATURES.EHR_IMMUNISATIONS)) {
        dispatch(getImmunisationsThunk());
      }
      if (flagActive(FEATURES.EHR_CONDITIONS)) {
        dispatch(getConditionsThunk());
      }
      if (flagActive(FEATURES.EHR_MEDICATIONS)) {
        dispatch(getMedicationsThunk());
      }
      if (flagActive(FEATURES.EHR_ALLERGIES)) {
        dispatch(getAllergiesThunk());
      }
    }
  }, [ehrStatus, dispatch]);

  return (
    <>
      <PortalLayout>
        <Box mt={2} mb={1}>
          <Typography textAlign="center" variant="h5" component="h1" paragraph>
            {t('ehr.title')}
          </Typography>
        </Box>
        {(ehrStatus === EhrStatus.PENDING || ehrStatus === EhrStatus.FAILED) && (
          <SectionBox>
            <Message title="Pending - " severity="info">
              {t('ehr.text')}
            </Message>
            <Box mb={3} textAlign="center">
              <Pulse />
            </Box>
          </SectionBox>
        )}
        {ehrStatus === EhrStatus.SUCCESS && (
          <Tabs tracked>
            {flagActive(FEATURES.EHR_IMMUNISATIONS) && (
              <EhrTab
                title={t('vaccinations.title') as string}
                showCaveat
                showCount
                data={immunisations}
                type="vaccinations"
                icon={<BiInjection />}
              >
                <SimpleList data={immunisations.response || []} type="vaccinations" />
              </EhrTab>
            )}
            {flagActive(FEATURES.EHR_CONDITIONS) && (
              <EhrTab
                title={t('conditions.title') as string}
                showCaveat
                data={conditions}
                type="conditions"
                icon={<BsJournalMedical />}
              >
                <SimpleList data={conditions.response || []} type="conditions" />
              </EhrTab>
            )}
            {flagActive(FEATURES.EHR_ALLERGIES) && (
              <EhrTab
                title={t('allergies.title') as string}
                showCaveat
                data={allergies}
                type="allergies"
                icon={<BiLeaf />}
              >
                <SimpleList data={allergies.response || []} type="allergies" />
              </EhrTab>
            )}
            {flagActive(FEATURES.EHR_MEDICATIONS) && (
              <EhrTab
                title={t('medications.title') as string}
                showCaveat
                showCount
                data={medications}
                type="medications"
                icon={<MdOutlineMedication />}
              >
                <MedicationsList data={medications.response || []} />
              </EhrTab>
            )}
          </Tabs>
        )}
      </PortalLayout>
      <Loader isVisible={isEhrBusy} label={t('loaders.waiting') as string} />
    </>
  );
}

export default EhrScene;
